import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { CardElement } from '@stripe/react-stripe-js';
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  route: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  donationDetails: any;
  selectedAmount: string;
  userImage: string;
  processingFee: string;
  churchFee: string;
  showDeleteModal: boolean;
  calculateFee: boolean;
  totalAmount: string;
  showPaymentMethodModal: boolean,
  showCardModal: boolean,

  showPaymentModal: boolean,
  showSuccessModal: boolean

  cardEmail: string;
  cardFirstname: string;
  cardLastname: string;
  cardNumber: string;
  cardDate: string;
  cardCVV: string;
  cardName: string;
  cardCountry: string;
  cardPostalcode: string;

  showCountryPicker: boolean;

  useAsBillingName: boolean;
  saveCard: boolean,
  countryList: any
  countryListHolder: any;
  isLoading: boolean;
  errorTimeout: ReturnType<typeof setTimeout> | number | null;
  addedCards: any,  
  selectedCard: any,
  exp_month: string,
  stripe: any,
  elements: any
  cardExpMonth: string,
  cardExpYear: string,
  stripeToken: string,
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  addDonationPaymentID: string = "";
  getCountriesID: string = "";
  getDonationID:string =''
  addCardID: string = ''
  getAddedCardsID: string = ''
  createPaymentIntentID: string = ""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {

      token: "",
      donationDetails: [],
      userImage: "",
      selectedAmount: "0.00",
      processingFee: "0.00",
      churchFee: "0.00",
           totalAmount: "0.00",
      showPaymentMethodModal: false,
      showCardModal: false,
      showDeleteModal: false,
      showPaymentModal: false,
      showSuccessModal: false,
      calculateFee: false,

      cardEmail: "",
      cardFirstname: "",
      cardLastname: "",
      cardNumber: "12345678901234",
      cardDate: "",
      cardCVV: "",
      cardName: "",
      cardCountry: "",
      cardPostalcode: "",

      useAsBillingName: false,
      saveCard: false,

      showCountryPicker: false,
      countryList: [],
      countryListHolder: [],
      isLoading:false,
      errorTimeout:null,
      addedCards: [],  
      selectedCard: null,
      exp_month: "",
      stripe: "",
      elements: "",
      cardExpMonth:"",
      cardExpYear:"",
      stripeToken:'',
      errorMessage: '',
    };


    this.onScreenLoad = this.onScreenLoad.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.onScreenLoad();
  }

  async componentWillUnmount() {
    if (this.state.errorTimeout) {
      clearTimeout(this.state.errorTimeout);
    }
  }
  

  async onScreenLoad() {
    let userImage = await getStorageData('userImage')

    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token") || "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODI1LCJleHAiOjE3NzI1NTc1MzUsInRva2VuX3R5cGUiOiJsb2dpbiJ9.K3pLUFPKrVuATdxxU346-xnCsdR524_jMHa8z96QsNi8XoMpagjCMAoPMerBu4LDzMCtXBz9OOPFjHhp2C1kfA";
    const donationId = searchParams.get("donation_id") || 2181;
    console.log("token, donation id", token, donationId);
    let data
    this.setState({ token: token, userImage: userImage },()=>{this.getDonationDetails(donationId as number); this.getAddedCards()})
    
  }
  getDonationDetails( id : number) {
    this.setState({isLoading: true})
    const header = {
      token:this.state.token,
    };

    const requestDonationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDonationID = requestDonationMessage.messageId;

    requestDonationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      
      `${configJSON.singlePostAPI}${id}`
    );
   
    requestDonationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDonationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestDonationMessage.id, requestDonationMessage);
  }

  

  updateCalculations(amount: string) {
    let churchFee = 0;
    let processingFee = 0;
    let totalAmount = 0;
  
    if (amount && amount.trim() !== "") {
      const numericAmount = parseFloat(amount);
  
      churchFee = numericAmount * 0.10;
  
      if (this.state.calculateFee) {
        processingFee = numericAmount * 0.10;
      }
  
      totalAmount = numericAmount + churchFee + processingFee;
    }
  
    this.setState({
      selectedAmount: amount,
      churchFee: (Math.round(churchFee * 100) / 100).toFixed(2),
      processingFee: (Math.round(processingFee * 100) / 100).toFixed(2),
      totalAmount: (Math.round(totalAmount * 100) / 100).toFixed(2),
    });
  }
  
  toggleProcessingFee() {
    this.setState(
      (prevState) => ({ calculateFee: !prevState.calculateFee }),
      () => this.updateCalculations(this.state.selectedAmount)
    );
  }
  






  async fetchPaymentIntentClientSecret() {
    try {
      this.setState({ isLoading: true });
  
      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };
  
      const body = {
        donation_id: this.state.donationDetails.id,
        amount_entered: parseFloat(this.state.selectedAmount),
        processing_fee: parseFloat(this.state.processingFee),
      };
  
      const response = await fetch(`${configJSON.createPaymentIntentEndpoint}`, {
        method: configJSON.createPaymentIntentMethodType,
        headers: header,
        body: JSON.stringify(body),
      });
  
      const data = await response.json();
  
      if (response.ok && data.client_secret) {
        return data.client_secret;
      } else {
        this.handleErrorMessage("Failed to create payment intent");
        return null;
      }
    } catch (error) {
      console.error("PaymentIntent error:", error);
      this.handleErrorMessage("Failed to create payment intent");
      return null;
    } finally {
      this.setState({ isLoading: false });
    }
  }
  

  donateSecurely() {
    this.setState({selectedCard: null, saveCard: false})
    if (this.state.selectedAmount && this.state.selectedAmount.trim() !== "" && parseFloat(this.state.selectedAmount) > 0) {
      this.setState({ showPaymentMethodModal: true });
    } else {
      this.setState({ errorMessage: "Please select or enter a valid amount" });
  
      if (this.state.errorTimeout) {
        clearTimeout(this.state.errorTimeout);
      }
  
      let errorTimeout = setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 3000);

      this.setState({errorTimeout})
    }
  }

  async saveCardandDonate(stripe: any, elements: any) {
    
    if (!this.state.cardEmail.trim()) {
      this.handleErrorMessage("Please enter a valid email");
      return;
    }
    if (!this.state.cardFirstname.trim()) {
      this.handleErrorMessage("Please enter a valid first name");
      return;
    }
    if (!this.state.cardLastname.trim()) {
      this.handleErrorMessage("Please enter a valid last name");
      return;
    }
    if (!this.state.cardName.trim()) {
      this.handleErrorMessage("Please enter a valid Card name");
      return;
    }
    // if (!this.state.cardCountry.trim()) {
    //   this.handleErrorMessage("Please select a country");
    //   return;
    // }
    if (!this.state.cardPostalcode.trim()) {
      this.handleErrorMessage("Please enter a postal code");
      return;
    }
  
    if (!stripe || !elements) {
      this.handleErrorMessage("Stripe has not loaded yet");
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      this.handleErrorMessage("Please enter your card details");
      return;
    }
  
    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      this.handleErrorMessage(error.message);
      return;
    }
    this.setState({
      selectedCard: "credit/debit", 
      showPaymentModal: true,       
      showCardModal: false,
      stripeToken: token.id,
      cardNumber: token.card.last4,
      cardExpYear: token.card.exp_year,
      cardExpMonth: token.card.exp_month,
    });
  
  }
  
  handleErrorMessage(message: string) {
    if (!message) return;
  
    if (this.state.errorTimeout) {
      clearTimeout(this.state.errorTimeout);
    }
  
    this.setState({ errorMessage: message });
  
    const errorTimeout = setTimeout(() => {
      this.setState({ errorMessage: "", errorTimeout: null });
    }, 3000);
  
    this.setState({ errorTimeout });
  }
  
  
  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallid = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.setState({isLoading:false})
    if (apiRequestCallid === this.getDonationID) {
      if (!responseJson.errors) {
        this.setState({ donationDetails:responseJson.data})

      }
    }


    if (apiRequestCallid === this.addCardID) {
      this.setState({ isLoading: false });
    
      if (!responseJson.errors) {
        this.handleErrorMessage("Card added successfully");
  
      } else {
        this.handleErrorMessage("Failed to add card");
      }
    }
    
    if (apiRequestCallid === this.addDonationPaymentID) {
      this.setState({ isLoading: false });
    
      if (!responseJson.error) {
        this.setState({ showSuccessModal: true });
        this.handleErrorMessage("Donation payment completed");

      } else {
        this.setState({ showSuccessModal: true });
        this.handleErrorMessage("Donation payment failed");
      }
    }
    
    if (apiRequestCallid === this.getAddedCardsID) {
      if (responseJson?.data) {
        this.setState({ addedCards: responseJson.data });
      } else {
        this.setState({ errorMessage: "No cards found" });
      }
    }

    

  }



  selectCard(card: any) {

    this.setState({
      selectedCard: card,
      cardEmail: card.email,
      cardFirstname: card.first_name,
      cardLastname: card.last_name,
      cardName: `${card.first_name} ${card.last_name}`,
      cardCountry: card.country,
      cardPostalcode: card.zip_code,
      cardNumber: card.card_number,
      exp_month: card.exp_month, 
      cardExpMonth: card.exp_month,
      cardExpYear: card.exp_year,
      cardCVV: card.cvc || "", 
    });
  }

  clickDonateNow(stripe: any, elements: any){
    (this.state.selectedCard &&  this.state.selectedCard !== "credit/debit") ? 
    this.setState({
      saveCard: false,
      showPaymentMethodModal: false,
      showPaymentModal: true,
      showCardModal: false,
      stripe:stripe,
      elements: elements
    })
    
    :  this.saveCardandDonate(stripe, elements)

  }

  maskCardNumber = (cardNumber: string) => {
    if (!cardNumber) return '';
    if (cardNumber.length === 4) {
      return `**** **** **** ${cardNumber}`;
    }
    if (typeof cardNumber !== 'string' || cardNumber.length < 9) {
      return 'Invalid card number';
    }
    const firstFour = cardNumber.slice(0, 4);
    const lastFour = cardNumber.slice(-4);
    const maskedNumber = cardNumber.slice(4, -4).replace(/\d/g, '*');
    return `${firstFour} ${maskedNumber} ${lastFour}`;
  };


  navigateToStripe() {
    this.setState({ selectedCard:"credit/debit" })

  }


  handleDeleteCard(){
    this.setState({showDeleteModal: true})
  }

  
  addCard(){
    this.setState({isLoading: true});
    const header={
      token: this.state.token,
      "Content-Type" :  configJSON.addCardContentType   
    }

    const body= {
      email: this.state.selectedCard?.email || this.state.cardEmail,
      first_name: this.state.selectedCard?.first_name || this.state.cardFirstname,
      last_name: this.state.selectedCard?.last_name || this.state.cardLastname,
      phone_number: this.state.selectedCard?.phone_number || "1234567890", 
      address: this.state.selectedCard?.address || "123 Main St",     
      zip_code: this.state.cardPostalcode || "12345",
      state: "CA",
      city: "San Francisco",
      country:  this.state.selectedCard?.country || this.state.cardCountry || "USA",
      card_number: this.state.selectedCard?.card_number || this.state.cardNumber,
      exp_month: this.state.selectedCard?.expiry || "12",
      exp_year: "26",
      cvc: this.state.selectedCard?.cvv || "123",       

    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addCardEndpoint);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.addCardMethodType);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    runEngine.sendMessage(requestMessage.id, requestMessage)

    this.createDonationPayment()

  }


  async addCardAndPayNow() {

    // const { stripe, elements } = this.state;

    // if (!stripe || !elements) {
    //   this.handleErrorMessage("Stripe is not initialized");
    //   return;
    // }
  
    // const clientSecret = await this.fetchPaymentIntentClientSecret();
    // if (!clientSecret) return;
  
    // // Confirm payment using the client secret
    // const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //     billing_details: {
    //       email: this.state.cardEmail,
    //       name: `${this.state.cardFirstname} ${this.state.cardLastname}`,
    //     },
    //   },
    // });
  
    // if (error) {
    //   this.handleErrorMessage(error.message);
    //   return;
    // }
  
    // if (paymentIntent.status === "succeeded") {
    //   this.setState({stripeToken: paymentIntent.id})      // Payment succeeded! Notify backend and show success modal
      
    //   // this.createDonationPayment();
    //   if (this.state.saveCard) {
    //     this.addCard();
    //   }else{
    //     this.createDonationPayment()
    //   }
    // } else {
    //   this.handleErrorMessage("Payment failed or incomplete");
    // }
  
    if (this.state.saveCard) {
      this.addCard();
    }else{
      this.createDonationPayment()
    }

  }
  


  getAddedCards() {
    this.setState({ isLoading: true });
  
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.getAddedCardsContentType,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.getAddedCardsID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddedCardsEndpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddedCardsMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  async createDonationPayment() {

    this.setState({ isLoading: true });
  
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.addDonationPaymentContentType,
    };
  
    const body = {
      donation_id: this.state.donationDetails?.id, 
      amount_entered: parseFloat(this.state.selectedAmount),
      processing_fee: parseFloat(this.state.processingFee),
      payment_token: this.state.stripeToken 

    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.addDonationPaymentID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addDonationEndpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addDonationPaymentMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

}


// Customizable Area End
