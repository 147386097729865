export const confetti1 = require("../assets/view1.svg")
export const confetti2 = require("../assets/view2.svg")

export const confetti3 = require("../assets/view3.svg")

export const confetti4 = require("../assets/view4.svg")
export const dollarIcon = require("../assets/dollar.svg")
export const coinIcon = require("../assets/coin.svg")
export const visaIcon = require("../assets/visa.svg")
export const cardIcon = require("../assets/card.svg")



