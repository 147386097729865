import React from "react";
// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController";
import { confetti1, confetti2, confetti3, confetti4, dollarIcon, coinIcon, visaIcon, cardIcon } from "./assets";

import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Avatar,
  Divider,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Radio,
} from "@material-ui/core";
import { ArrowBack, Lock, CheckBox, CheckBoxOutlineBlank, ShoppingCart, Delete } from "@material-ui/icons";
import moment from "moment";
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { getPrimaryColor, getSecondaryColor } from "../../../components/src/GlobalStyles";
declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}
// Customizable Area End



export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderAmountButton = (amount: string) => {
    return (
      <Button
        data-testid={"amountBtn" + amount}
        variant={this.state.selectedAmount === amount ? "contained" : "outlined"}
        style={{
          background: this.state.selectedAmount === amount ? `linear-gradient(to right, ${getPrimaryColor()}, ${getSecondaryColor()})` : "#fff",
          color: this.state.selectedAmount === amount ? "#fff" : "#000",
          borderRadius: 30,
          height: 48,
          width: 80,
        }}
        onClick={() => this.updateCalculations(amount)}
      >
        ${amount}
      </Button>
    );
  };
  renderAmountSection = () => {
    return (<Box mt={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="subtotal-row"
      >
        <Typography
          variant="body1"
          data-testid="subtotal-label"
        >
          Subtotal
        </Typography>

        <Typography
          variant="body1"
          data-testid="subtotal-value"
        >
          ${Math.round((Number(this.state.selectedAmount) * 100) / 100).toFixed(2)}
        </Typography>
      </Box>
      {/* Mandatory church donation fee */}
      <Box display="flex" justifyContent="space-between" mt={1}> <Typography variant="body1"><Checkbox checked={true} data-testid="processingCheckbox" style={{ color: "deepskyblue" }} />Support your church*</Typography> <Typography variant="body1">${this.state.churchFee}</Typography> </Box>
      {/* Optional processing fee checkbox */}
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Typography variant="body1">
          <Checkbox
            checked={this.state.calculateFee}
            onChange={() => this.toggleProcessingFee()}
            data-testid="processingFeeCheckbox"
            style={{ color: "deepskyblue" }}
          />
          Processing fee
        </Typography>
        <Typography variant="body1" data-testid="processingFeeAmount">${this.state.churchFee}</Typography>
      </Box>
      <Divider style={{ margin: "10px 0" }} /> <Box display="flex" justifyContent="space-between" fontWeight="bold"> <Typography variant="h6" style={{ fontWeight: "bold" }}>Total</Typography> <Typography variant="h6" style={{ fontWeight: "bold" }}>${this.state.totalAmount}</Typography> </Box> </Box>);
  };
  renderTotalSection = () => {
    return (
      <Box mt={2} data-testid="totalSectionContainer">
        {/* Subtotal */}
        <Box display="flex" justifyContent="space-between" data-testid="subtotalRow">
          <Typography variant="body1" data-testid="subtotalLabel">Subtotal</Typography>
          <Typography variant="body1" data-testid="subtotalValue">${Math.round((Number(this.state.selectedAmount)*100)/100).toFixed(2)}</Typography>
        </Box>
        {/* Mandatory church donation fee */}
        <Box display="flex" justifyContent="space-between" mt={1} data-testid="churchFeeRow">
          <Typography variant="body1" data-testid="churchFeeLabel">
            Support your church*
          </Typography>
          <Typography variant="body1" data-testid="churchFeeValue">${this.state.churchFee}</Typography>
        </Box>
        {/* Optional processing fee */}
        {this.state.calculateFee && <Box display="flex" justifyContent="space-between" mt={1} data-testid="processingFeeRow">
          <Typography variant="body1" data-testid="processingFeeLabel">
            Processing fee
          </Typography>
          <Typography variant="body1" data-testid="processingFee">${this.state.churchFee}</Typography>
        </Box>}
        <Divider style={{ margin: "10px 0" }} data-testid="divider" />
        {/* Total */}
        <Box display="flex" justifyContent="space-between" fontWeight="bold" data-testid="totalRow">
          <Typography variant="h6" style={{ fontWeight: "bold" }} data-testid="totalLabel">Total</Typography>
          <Typography variant="h6" style={{ fontWeight: "bold" }} data-testid="totalValue">${this.state.totalAmount}</Typography>
        </Box>
      </Box>
    );
  };
  renderConfettiBackground = () => {
    return (
      <>
        <img
          src={confetti1}
          alt="confetti"
          style={{
            position: "absolute",
            top: "8px",
            left: "10px",
            width: "25px",
          }}
        />
        <img
          src={confetti2}
          alt="confetti"
          style={{
            position: "absolute",
            top: "8px",
            right: "10px",
            width: "25px",
          }}
        />
        <img
          src={confetti3}
          alt="confetti"
          style={{
            position: "absolute",
            bottom: "8px",
            left: "20px",
            width: "20px",
          }}
        />
        <img
          src={confetti4}
          alt="confetti"
          style={{
            position: "absolute",
            bottom: "8px",
            right: "20px",
            width: "20px",
          }}
        />
        {/* User Avatar */}
        <Avatar
            src={coinIcon}
            style={{
              width: 64,
              height: 64,
              marginBottom: "12px",
              border: "3px solid white",
            }}
            data-testid="paymentUserAvatar"
          />
      </>
    );
  };
  renderPaymentMethodModal = () => {
    return (
          <ElementsConsumer>
            {({ stripe, elements }) => {
              return (

                <Dialog
                  open={this.state.showPaymentMethodModal}
                  onClose={() => this.setState({ showPaymentMethodModal: false })}
                  fullWidth
                  fullScreen
                  maxWidth="sm"
                  data-testid="paymentMethodModal"
                >
                  <DialogTitle data-testid="paymentMethodTitle">
                  <Box style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}>
                      <Box 
                      width={{
                        xs: "100%",
                        sm: "80%",
                        md: "60%",
                        lg: "50%"
                      }}>
                        <Box display="flex" alignItems="center" mt={4} justifyContent="center">
                          <IconButton
                            data-testid="closePaymentModalBtn"
                            onClick={() => this.setState({ showPaymentMethodModal: false })}
                            style={{ width: "15%" }}
                          >
                            <ArrowBack fontSize="large" />
                          </IconButton>
                          <Typography
                            variant="h5"
                            style={{ flexGrow: 1, fontWeight: "bold", marginLeft: "12%" }}
                          >
                            Payment Method
                          </Typography>
                        </Box>
                    {this.state.errorMessage && (
                      <Box p={1} bgcolor="error.main" color="white" textAlign="center" mb={2}>
                        {this.state.errorMessage}
                      </Box>
                    )}
                    </Box>
                    </Box>
                  </DialogTitle>

                  <DialogContent>
                    <Box style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      minHeight: "100vh"
                    }}>
                      <Box 
                      width={{
                        xs: "100%",
                        sm: "80%",
                        md: "60%",
                        lg: "50%"
                      }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "16px",
                        padding: "24px",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.08)", // Smooth shadow
                        width: "85%",
                        // maxWidth: "340px",
                        margin: "auto",
                        position: "relative",
                        border: "1px solid rgb(223, 227, 235)"

                      }}
                      >
                      {this.renderConfettiBackground()}


                      <Typography variant="h6" data-testid="paymentModalHeading" style={{ fontWeight: 600 }}>
                        Get a reward by helping others
                      </Typography>

                      <Box display="flex" alignItems="center" justifyContent="center" mt={1} >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          px={2}
                          py={1}
                          style={{
                            borderRadius: "20px",
                            backgroundColor: "#F7F8FA",
                            fontWeight: "bold",
                            fontSize: "16px",
                            border: "1px solid rgb(43, 47, 53)"
                          }}
                        >
                          +200
                          <img src={coinIcon} alt="Coin Icon" style={{ width: "22px", height: "22px", marginLeft: "4px" }} />
                        </Box>
                      </Box>

                      <Typography
                        variant="body2"
                        data-testid="paymentModalDescription"
                        style={{ marginTop: "8px", color: "#6B7280" }}
                      >
                        Finish your donation and earn a reward
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      border="1px solid #E2E8F0"
                      borderRadius="10px"
                      padding="12px"
                      mt={3}
                      onClick={() => this.navigateToStripe()}
                      style={{ cursor: "pointer" }}
                      data-testid="cardBtn"
                    >
                          <Box 
                            display="flex" alignItems="center"
                            style={{
                              cursor: "pointer",
                              width: "340px",
                              // justifyContent: "center", alignContent: "center", alignItems: "center"
                            }}
                          >
                        {/* Radio Button (Replaces Checkbox) */}
                        <Radio
                          checked={this.state.selectedCard === "credit/debit"}
                          style={{
                            color: "#1B74E4", // Blue on select
                            width: "20px",
                            height: "20px"
                          }}
                        />

                        <img src={visaIcon} alt="Card Icon" style={{ width: 24, height: 24, marginLeft: "8px" }} />

                        <Typography variant="body1" style={{ marginLeft: "8px" }}>
                          Credit or Debit
                        </Typography>
                      </Box>

                    </Box>

                    {this.state.selectedCard === "credit/debit" && (
                      <Box
                        mt={1}
                        width="90%"
                        maxWidth="400px"
                        borderRadius="16px"
                        bgcolor="#FFFFFF"
                        padding="24px"
                        mx="auto"
                        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
                        display="flex"
                        flexDirection="column"
                      >
                        <Box mb={2}>
                          <TextField
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            value={this.state.cardEmail}
                            onChange={(e) => this.setState({ cardEmail: e.target.value })}
                            InputProps={{
                              style: {
                                borderRadius: '12px',
                              },
                            }}
                            data-testid="inputEmail"
                          />
                        </Box>

                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Box mr={1} width="50%">
                            <TextField
                              label="First Name"
                              variant="outlined"
                              fullWidth
                              value={this.state.cardFirstname}
                              onChange={(e) => this.setState({ cardFirstname: e.target.value })}
                              InputProps={{
                                style: { borderRadius: '12px' },
                              }}
                              data-testid="inputFirstName"
                            />
                          </Box>
                          <Box ml={1} width="50%">
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              fullWidth
                              value={this.state.cardLastname}
                              onChange={(e) => this.setState({ cardLastname: e.target.value })}
                              InputProps={{
                                style: { borderRadius: '12px' },
                              }}
                              data-testid="inputLastName"

                            />
                          </Box>
                        </Box>

                        <Box mb={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.useAsBillingName}
                                onChange={() => this.setState({ useAsBillingName: !this.state.useAsBillingName })}
                                icon={<CheckBoxOutlineBlank style={{ borderRadius: 4 }} />}
                                checkedIcon={<CheckBox style={{ color: '#1B74E4', borderRadius: 4 }} />}
                              />
                            }
                            label="Use as billing name"
                          />
                        </Box>

                        <Box mb={2} width="100%">
                          <Box
                            style={{
                              border: '1px solid #CBD5E0',
                              borderRadius: '16px',
                              padding: '10px 12px',
                              height: '38px',
                            }}
                          >
                            <CardElement
                              options={{
                                style: {
                                  base: {
                                    fontSize: '16px',
                                    color: '#32325d',
                                    '::placeholder': { color: '#a0aec0' },
                                  },
                                  invalid: {
                                    color: '#fa755a',
                                    iconColor: '#fa755a',
                                  },
                                },
                              }}
                              onChange={(event) => {
                                if (event.error) {
                                  this.handleErrorMessage(event.error.message);
                                } else if (!event.complete) {
                                  this.handleErrorMessage("Please complete card information.");
                                } else {
                                  this.setState({ errorMessage: "" });
                                }
                              }}
                            />
                          </Box>
                        </Box>
                        <Box mb={2}>
                          <TextField
                            label="Name on Card"
                            variant="outlined"
                            fullWidth
                            value={this.state.cardName}
                            onChange={(e) => this.setState({ cardName: e.target.value })}
                            InputProps={{
                              style: {
                                borderRadius: '12px',
                              },
                            }}
                            data-testid="inputCardNumber"
                          />
                        </Box>

                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Box mr={1} width="50%">
                            <FormControl
                              variant="outlined"
                              fullWidth
                            >
                              <Select
                                value={this.state.cardCountry}
                                displayEmpty
                                onChange={(e) => this.setState({ cardCountry: e.target.value as string })}
                                inputProps={{ 'aria-label': 'Country' }}
                                style={{
                                  borderRadius: '12px',
                                  height: '56px',
                                }}
                              >
                                <MenuItem value="">United States</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box ml={1} width="50%">
                            <TextField
                              label="Postal Code"
                              variant="outlined"
                              fullWidth
                              value={this.state.cardPostalcode}
                              onChange={(e) => this.setState({ cardPostalcode: e.target.value })}
                              InputProps={{
                                style: {
                                  borderRadius: '12px',
                                },
                              }}
                            />
                          </Box>
                        </Box>

                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.saveCard}
                                onChange={() => this.setState({ saveCard: !this.state.saveCard })}
                                icon={<CheckBoxOutlineBlank style={{ borderRadius: 4 }} />}
                                checkedIcon={<CheckBox style={{ color: '#1B74E4', borderRadius: 4 }} />}
                              />
                            }
                            label={
                              this.state.addedCards?.length
                                ? <> Save card for future donations <br/> (replaces previously saved card)</>
                                : <> Save card for future donations </>
                            }
                          />
                        </Box>
                      </Box>
                    )}


                    {this.state.addedCards?.length && this.state.addedCards.map((card: any, index: number) => (
                      <Box
                        key={card.id}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        border="1px solid #E2E8F0"
                        borderRadius="10px"
                        padding="12px"
                        mt={3}
                        style={{ cursor: "pointer" }}
                      // data-testid={`cardBtn-${index}`}
                      >
                        <Box display="flex" alignItems="center" onClick={() => this.selectCard(card)}
                          data-testid={`cardBtn-${index}`}
                        >
                          <Radio
                            checked={this.state.selectedCard?.id === card.id}
                            style={{
                              color: "#1B74E4",
                              width: "20px",
                              height: "20px"
                            }}
                          />
                          <img src={cardIcon} alt="Card Icon" style={{ width: 24, height: 24, marginLeft: "8px" }} />
                          <Box display="flex" alignItems="center" flexDirection={"column"} ml={1} flexGrow={1}>
                            <Typography data-testid="paymentMethodValue" variant="body2" style={{ marginRight: "5px" }}>
                              {`   ${this.maskCardNumber(card.card_number)}`}
                            </Typography>
                          </Box>

                        </Box>
                        <IconButton
                          data-testid="deleteCardBtn"
                          onClick={()=>this.handleDeleteCard()} >
                          <Delete />
                        </IconButton>
                      </Box>
                    ))}
                    <Divider style={{ margin: "10px 0" }} />
                    <Box mt={8} display="flex" justifyContent="space-between" fontWeight="bold">
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>Your Donation</Typography>
                    </Box>


                    <Box mt={1} mb={2}>{this.renderTotalSection()}</Box>
                    <Box mt={5} mb={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <Button
                        disabled={!this.state.selectedCard}
                        data-testid="btnDonateSecurely"
                        variant="contained"
                        style={{
                          width: "100%",
                          height: "50px", // Match Figma height
                          padding: "16px", // Match Figma padding
                          borderRadius: "30px", // Match Figma border radius
                          background: !this.state.selectedCard ? "gray" : "linear-gradient(to right, #1BCDE3, #227BF8)", // Match Figma gradient
                          color: "#fff",
                          fontSize: "16px", // Adjust font size for better visibility
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px" // Space between icon and text
                        }}

                        onClick={() => { this.clickDonateNow(stripe, elements) }}
                        startIcon={<ShoppingCart />} // Left Icon (Cart)
                      >
                        Donate Now
                      </Button>
                    </Box>
                    </Box>
                    </Box>

              </DialogContent>
            </Dialog>
          )
        }}
      </ElementsConsumer>
    );
  };


  renderPaymentSummaryModal = () => {
    return (
      <Dialog
        data-testid="paymentSummaryModal"
        open={this.state.showPaymentModal}
        onClose={() => this.setState({ showPaymentModal: false, showCardModal: true })}
        fullWidth
        fullScreen
        maxWidth="sm"
      >
        <DialogTitle  data-testid="paymentSummaryTitle">
          <Box style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            // minHeight: "100vh"
          }}>
            <Box
              width={{
                xs: "100%",
                sm: "80%",
                md: "60%",
                lg: "50%"
              }}>
              <Box style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
              }}
                mt={4}
              >
            <IconButton
              onClick={() => this.setState({ showPaymentModal: false, showPaymentMethodModal: true })}
              data-testid="closePaymentSummaryBtn"
              style={{ width:"15%" }}
            >
              <ArrowBack fontSize="large" />
            </IconButton>
            <Typography variant="h5" style={{ fontWeight: "bold", marginLeft:"10%", flexGrow: 1 }}>
              Payment Summary
            </Typography>
          </Box>
          {this.state.errorMessage && (
            <Box p={1} bgcolor="error.main" color="white" textAlign="center" mb={2}>
              {this.state.errorMessage}
            </Box>
          )}
          </Box>
          </Box>
        </DialogTitle>

        <DialogContent style={{ paddingTop: "5px", margin: "15px" }}>
          <Box style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            minHeight: "100vh"
          }}>
            <Box
              width={{
                xs: "100%",
                sm: "80%",
                md: "60%",
                lg: "50%"
              }}>
          <Box mb={2}>
            <Typography data-testid="emailLabel" variant="subtitle2" style={{ fontWeight: 600 }}>
              Email
            </Typography>
            <Typography data-testid="emailValue" variant="body2" style={{ color: "text.secondary" }}>
              {this.state.cardEmail || this.state.selectedCard?.email}
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography data-testid="nameLabel" variant="subtitle2" style={{ fontWeight: 600 }}>
              First name and Last name
            </Typography>
            <Typography data-testid="nameValue" variant="body2" style={{ color: "text.secondary" }}>
              {this.state.cardFirstname || this.state.selectedCard?.first_name} {this.state.cardLastname || this.state.selectedCard?.last_name}
            </Typography>
          </Box>

          <Divider data-testid="divider" style={{ margin: '16px 0' }} />

          <Box mb={2}>
            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
              Your Donation
            </Typography>
            {this.renderTotalSection()}
          </Box>

          <Box mb={2}>
            <Typography data-testid="paymentMethodLabel" variant="subtitle2" style={{ fontWeight: 600 }}>
              Payment Method
            </Typography>
            <Box display="flex" alignItems="center">
              <img src={cardIcon} alt="Card Icon" style={{ width: 44, height: 32, marginLeft: "0px" }} />
              <Box display="flex" alignItems="center" flexDirection={"column"} ml={1}>

                <Typography data-testid="paymentMethodValue" variant="body2" style={{ marginLeft: "5px" }}>
                  {`${this.maskCardNumber(this.state.selectedCard?.card_number || this.state.cardNumber)}`}
                </Typography>
                <Typography data-testid="paymentMethodValue" variant="body2" style={{ marginLeft: "0px" }}>
                  {`${this.state.selectedCard?.first_name || this.state.cardFirstname || "name"} ${this.state.selectedCard?.last_name || this.state.cardLastname || "surname"}`}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Typography data-testid="transactionDateLabel" variant="body2">
              Transaction Date
            </Typography>
            <Typography data-testid="transactionDateValue" variant="body2">
              {moment().format("MM/DD/YY")}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Typography data-testid="transactionIDLabel" variant="body2">
              Transaction ID
            </Typography>
            <Typography data-testid="transactionIDValue" variant="body2">
              {"XXXXXX"}
            </Typography>
          </Box>
          </Box>
          </Box>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center', padding: '24px' }}>
          <Box style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            // minHeight: "100vh"
          }}>
            <Box
              width={{
                xs: "100%",
                sm: "80%",
                md: "60%",
                lg: "50%"
              }}>
          <Button
            data-testid="createDonationBtn"
            onClick={() => this.addCardAndPayNow()}
            variant="contained"
            fullWidth
            style={{
              height: '50px',
              width:"100%",
              borderRadius: '30px',
              background: 'linear-gradient(to right, #1BCDE3, #227BF8)',
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: '16px',
              textTransform: 'none',
            }}
          >
            Pay Now
          </Button>
          </Box>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  renderSuccessModal = () => {
    return (
      <Dialog
        data-testid="successModal"
        open={this.state.showSuccessModal}
        fullWidth
        maxWidth="sm"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "24px",
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.08)", // Smooth shadow
            width: "85%",
            maxWidth: "340px",
            margin: "auto",
            position: "relative",
            border: "1px solid rgb(223, 227, 235)"

          }}
        >
          {this.renderConfettiBackground()}

          <Typography variant="h6" data-testid="successModalTitle" style={{ fontWeight: 600, justifyContent:"center", textAlign:"center", alignItems:"center" }}>
            Thank you for sharing!
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="center" mt={1} >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              px={2}
              py={1}
              style={{
                borderRadius: "20px",
                backgroundColor: "#F7F8FA",
                fontWeight: "bold",
                fontSize: "16px",
                border: "1px solid rgb(43, 47, 53)"
              }}>
              +200
              <img src={coinIcon} alt="Coin Icon" style={{ width: "22px", height: "22px", marginLeft: "4px" }} />
            </Box>
          </Box>

          <Typography
            variant="body2"
            data-testid="successMessage"
            style={{ marginTop: "8px", color: "#6B7280", justifyContent:"center", textAlign:"center", alignItems:"center" }}
          >
            You help others and get rewards for your generous actions.
          </Typography>


          <Box mt={5} mb={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Button
              data-testid="btnSuccessDonate"
              variant="contained"
              style={{
                width: "100%", 
                height: "50px", 
                padding: "16px", 
                borderRadius: "30px", 
                background: !this.state.selectedCard ? "gray" : "linear-gradient(to right, #1BCDE3, #227BF8)", // Match Figma gradient
                color: "#fff",
                fontSize: "16px", 
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px" 
              }}
              onClick={() => {
                console.log("Go Back To The App")
                if (window.ReactNativeWebView) {
                  console.log("ReactNativeWebView");
                  window.ReactNativeWebView.postMessage("close");
                }
              }}
            >
              Go Back To The App
            </Button>
          </Box>
        </Box>



      </Dialog>
    );
  };
  renderDeleteConfirmModal = () => {
    return (
      <Dialog
        data-testid="deleteModal"
        open={this.state.showDeleteModal}
        fullWidth
        maxWidth="sm"
        onClose={()=>{this.setState({showDeleteModal:false})}}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "24px",
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.08)", // Smooth shadow
            width: "85%",
            maxWidth: "340px",
            margin: "auto",
            position: "relative",
            border: "1px solid rgb(223, 227, 235)"

          }}
        >
          {this.renderConfettiBackground()}
          <Typography variant="h6" data-testid="deleteModalTitle1" style={{ fontWeight: 600, justifyContent:"center", textAlign:"center", alignItems:"center" }}>
            Are You Sure ?
          </Typography>
          <Typography variant="h6" data-testid="deleteModalTitle2" style={{ fontWeight: 600, justifyContent:"center", textAlign:"center", alignItems:"center" }}>
            You Want To Remove Your Saved Card?
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="center" mt={1} >
            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              px={2}
              py={1}
              style={{
                borderRadius: "20px",
                backgroundColor: "#F7F8FA",
                fontWeight: "bold",
                fontSize: "16px",
                border: "1px solid rgb(43, 47, 53)"
              }}
            > */}
              <img src={cardIcon} alt="Coin Icon" style={{ width: "42px", height: "42px"}} />
            {/* </Box> */}
          </Box>

          <Typography
            variant="body2"
            data-testid="deleteMessage"
            style={{ marginTop: "8px", color: "#6B7280", justifyContent:"center", textAlign:"center", alignItems:"center" }}
          >
            Once Removed you won't be able to it use again !!
          </Typography>


          <Box mt={5} mb={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width:"100%"

            }}>
            <Button
              data-testid="btnCancelDelete"
              variant="contained"
              style={{
                width: "35%", 
                height: "50px", 
                padding: "16px", 
                borderRadius: "30px", 
                background: "linear-gradient(to right, #1BCDE3, #227BF8)", // Match Figma gradient
                color: "#fff",
                fontSize: "16px", 
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px" 
              }}
              onClick={() => {this.setState({showDeleteModal: false})}}
            >
              CANCEL
            </Button>
            <Button
              data-testid="btnConfirmDelete"
              variant="contained"
              style={{
                width: "55%", 
                height: "50px", 
                padding: "16px", 
                borderRadius: "30px", 
                background: "linear-gradient(to right, #1BCDE3, #227BF8)", // Match Figma gradient
                color: "#fff",
                fontSize: "16px", 
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px" 
              }}
              onClick={() => { }}
            >
              CONFIRM
            </Button>
          </Box>
        </Box>



      </Dialog>
    );
  };
  // Customizable Area End


  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          minHeight: "100vh"
        }}
      >
        <Box p={3}

          width={{
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "50%"
          }}
        >
          {/* Header */}
          <Box display="flex" alignItems="center" mt={4} justifyContent="center">
            <IconButton
              data-testid="goBackBtn"
              onClick={() => {
                console.log("Go Back To The App")
                if (window.ReactNativeWebView) {
                  console.log("ReactNativeWebView");
                  window.ReactNativeWebView.postMessage("close");
                }
              }}

              style={{ width: "15%" }}
            >
              <ArrowBack fontSize="large" />
            </IconButton>
            <Typography
              variant="h5"
              style={{ flexGrow: 1, fontWeight: "bold", marginLeft: "26%" }}
            >
              Donate
            </Typography>
          </Box>
          <Card
            data-testid="navigationdetails"
            style={{
              width: "95%",
              borderRadius: 17,
              backgroundColor: "#fff",
              margin: "10px auto",
              paddingBottom: 10,
            }}
          >
            <CardContent>
              {this.state.donationDetails.attributes?.images?.length > 0 && (
                <img
                  src={this.state.donationDetails.attributes?.images[0].url}
                  alt="Donation"
                  style={{ width: "100%", height: 230, borderRadius: 20 }}
                />
              )}
              <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="body2" style={{ marginLeft: 8 }}>
                  {this.state.donationDetails.attributes?.description}
                </Typography>
              </Box>

              <Box mt={1} display="flex" alignItems="center">
                <Avatar
                  src={this.state.donationDetails.attributes?.user_image}
                />
                <Box ml={2}>
                  <Typography variant="subtitle1">
                    {this.state.donationDetails?.attributes?.name || "For Good Cause"}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {moment(this.state.donationDetails?.attributes?.created_at).format("MMM. DD, YYYY") || "20-20-2020"}
                  </Typography>
                </Box>
              </Box>

              <LinearProgress
                style={{ marginTop: 15, width: "100%", alignSelf: "center" }}
                variant="determinate"
                value={(this.state.donationDetails?.attributes?.amount_raised ?? 0) / 100}
              />

              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body1">
                  $ {this.state.donationDetails?.attributes?.amount_raised || 0} raised
                </Typography>
                <Typography variant="body1">
                  $ {this.state.donationDetails?.attributes?.targeted_amount || 0} goal
                </Typography>
              </Box>
              <Divider style={{ margin: "10px 0" }} />
              <Box mt={3} display="flex" justifyContent="space-evenly">
                {this.renderAmountButton("1")}
                {this.renderAmountButton("5")}
                {this.renderAmountButton("10")}
                {this.renderAmountButton("20")}
              </Box>

              <Box mt={1}

                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <TextField
                  data-testid="inputOtherAmount"
                  fullWidth
                  variant="outlined"
                  placeholder={this.state.selectedAmount ? "Other amount" : ""}
                  type="number"
                  value={this.state.selectedAmount}
                  onChange={(e) => this.updateCalculations(e.target.value)}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start"> <img src={dollarIcon} alt="$" style={{ width: "20px", height: "20px", marginLeft: "2px" }} /> </InputAdornment>),
                    style: {
                      height: "44px",  // Match Figma height
                      backgroundColor: "#FFFFFF", // White background
                      borderRadius: "10px", // Rounded corners
                      border: "1px solid skyblue",

                    }
                  }}
                />
              </Box>
              {/* error message UI shifted here */}
              {this.state.errorMessage && (
                <Box p={2} mt={2} bgcolor="error.main" color="white" textAlign="center">
                  {this.state.errorMessage}
                </Box>
              )}

              <Box
                mt={2}
                style={{
                  display: "flex",
                  width: "100%",
                  height: "48px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "linear-gradient(to right, #1BCDE3, #227BF8)",
                }}
              >
                <Typography variant="body1" style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  Your donation gives you
                </Typography>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body1" style={{ fontWeight: "bold", flexGrow: 1, justifyContent: "right", marginTop: "5px" }}>
                    200
                  </Typography>
                  <img src={coinIcon} alt="Coin" style={{ width: "24px", height: "24px", margin: "5px" }} />
                </Box>
              </Box>


              {this.renderAmountSection()}
            </CardContent>
          </Card>
          <Box display="flex" alignItems="center" mt={3}>
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              *Support your church with a 10% Processing fee. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
          <Box mt={5}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Button
              data-testid="btnDonateSecurely"
              variant="contained"
              style={{
                width: "100%", // Match Figma width
                height: "50px", // Match Figma height
                padding: "16px", // Match Figma padding
                borderRadius: "30px", // Match Figma border radius
                background: "linear-gradient(to right, #1BCDE3, #227BF8)", // Match Figma gradient
                color: "#fff",
                fontSize: "16px", // Adjust font size for better visibility
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px" // Space between icon and text
              }}
              onClick={() => this.donateSecurely()}
              startIcon={<ShoppingCart />} // Left Icon (Cart)
            >
              Donate Securely
            </Button>
          </Box>



        <Box display="flex" alignItems="center" mt={3}>
          <Lock color="disabled" />
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            Secure checkout with data encryption
          </Typography>
        </Box>

        {this.renderPaymentMethodModal()}
        {this.renderPaymentSummaryModal()}
        {this.renderSuccessModal()}
        {this.renderDeleteConfirmModal()}

        </Box>
      </Box>

    // Customizable Area End

    );
  }
}

// Customizable Area Start
// Customizable Area End

