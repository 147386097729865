


import { setStorageData, getStorageData } from "framework/src/Utilities";
// import { imageLogo } from "./assets";
type Color = string;
let primaryColor: Color = "#19CDE3";
let secondaryColor: Color = "#2278FB";
let tertiaryColor: Color = "#19CDE3";
let quaternaryColor: Color = "#2278FB";

// var logoImage = imageLogo;

let appMainPrimary: Color = "#19CDE3";
let appMainSeconary: Color = "#2278FB";

const setColors = async (colors: any) => {
    if (colors.primaryColor !== undefined) {
        primaryColor = colors.primaryColor;
        await setStorageData('primaryColor', primaryColor);
    }
    if (colors.secondaryColor !== undefined) {
        secondaryColor = colors.secondaryColor;
        await setStorageData('secondaryColor', secondaryColor);
    }
    if (colors.tertiaryColor !== undefined) {
        tertiaryColor = colors.tertiaryColor;
        await setStorageData('tertiaryColor', tertiaryColor);
    }
    if (colors.quaternaryColor !== undefined) {
        quaternaryColor = colors.quaternaryColor;
        await setStorageData('quaternaryColor', quaternaryColor);
    }
};

const setLogo = async (logo: any) => {
    if (logo !== undefined) {
        await setStorageData('logoImage', logo);
    }
}

(async () => {
    try {
        const savedPrimaryColor = await getStorageData('primaryColor');
        if (savedPrimaryColor !== null) {
            primaryColor = savedPrimaryColor;
        }

    const savedSecondaryColor = await getStorageData('secondaryColor');
    if (savedSecondaryColor !== null) {
        secondaryColor = savedSecondaryColor;
    }

    const savedTertiaryColor = await getStorageData('tertiaryColor');
    if (savedTertiaryColor !== null) {
        tertiaryColor = savedTertiaryColor;
    }

    const savedQuaternaryColor = await getStorageData('quaternaryColor');
    if (savedQuaternaryColor !== null) {
        quaternaryColor = savedQuaternaryColor;
    }

    if (savedPrimaryColor === null && savedSecondaryColor === null && savedTertiaryColor === null) {
        setColors({});
    }

    const logo = await getStorageData('logoImage');
    if (logo !== null) {

        // logoImage = { uri: logo };
    }
} catch (error) {
    console.error("Error loading colors from custom storage:", error);
}
})();

const getColors = async () => {
    return {
        primaryColor,
        secondaryColor,
        tertiaryColor
    };
};

const getPrimaryColor = (): Color => {
    return primaryColor;
};

const getSecondaryColor = (): Color => {
    return secondaryColor;
};

const getTertiaryColor = (): Color => {
    return tertiaryColor;
};

const getQuaterneryColor = (): Color => {
    return quaternaryColor;
};

const getMainPrimary = (): Color => {
    return appMainPrimary;
};

const getMainSeconary = (): Color => {
    return appMainSeconary;
};

// const getLogoImage = (defaultValue:{uri:null|string}=logoImage) => {
//     logoImage=defaultValue
//     return logoImage;
// };

export { setColors, getColors, getPrimaryColor, getSecondaryColor, getTertiaryColor, getQuaterneryColor, setLogo, getMainPrimary, getMainSeconary };