import React from "react";
import { View, StyleSheet } from "react-native";

const TopNav = () => {
  return (
    <View style={styles.main}>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#cccccc"
  }
});

export default TopNav;
